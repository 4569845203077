// src/pages/LandingPage.tsx
import React from 'react';
import SolutionHeader from './SolutionHeader';
import Description from '../service/Description';
import TechStacks from './TechStacks';


const Solutions: React.FC = () => {
    return (
        <div className=''>
           <SolutionHeader/>
           <Description/>
           <TechStacks/>
        </div>
    );
};

export default Solutions;
