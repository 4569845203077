import React from "react";

const OurWork = () => {
  const cardData = [
    {
      id: 1,
      image: "/Educationist.svg",
      title: "Educationist.ai",
      summary:
        "Revolutionizing education with AI-powered tools to enhance learning outcomes and streamline teaching processes.",
      linkText: "Read case study",
      link:"https://educationist.ai"
    },
    {
      id: 2,
      image: "/ITSM_LOGO.svg",
      title: "ITSM",
      summary:
        "Delivering seamless IT service management solutions to optimize workflows and improve service delivery.",
      linkText: "Read case study",
      link:"https://www.bizitsm.com"
      
    },
    {
      id: 3,
      image: "/CRM_LOGO.svg",
      title: "CRM",
      summary:
        "Empowering businesses with customer relationship management tools to boost engagement and drive sales growth.",
      linkText: "Read case study",
      link:"https://www.easymaktab.com/"

    },
  ];
  

  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto px-6  w-[80%]">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-5xl font-bold text-gray-800 bg-gradient-to-r from-accent to-[#3f0d09] bg-clip-text text-transparent">Our Work</h2>
          {/* <a
            href="#"
            className="text-blue-600 hover:text-blue-800 font-medium border-2 py-2 px-7 "
          >
            View all
          </a> */}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {cardData.map((card) => (
             <a
             key={card.id}
             href={card.link}
             target="_blank" // Open the link in a new tab
             rel="noopener noreferrer" // Security precaution
             className="bg-white shadow-xl border-slate-100 border rounded-lg hover:scale-105 transition-all hover:border-slate-200"
           >
            <div
              // key={card.id}
              // className="bg-white shadow-xl border-slate-100 border rounded-lg hover:scale-105 transition-all hover:border-slate-200 "
            >
              <img
                src={card.image}
                alt={card.title}
                className="w-full h-48 object-contain p-3"
                
              />
              <div className="p-6">
                <h3 className="text-lg font-bold text-gray-800 mb-2">
                  {card.title}
                </h3>
                <p className="text-gray-600 mb-4">{card.summary}</p>
                {/* <a
                  href="#"
                  className="text-blue-600 hover:text-blue-800 font-medium"
                >
                  {card.linkText}
                </a> */}
              </div>
            </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurWork;
