import { CheckmarkOutline } from '@carbon/icons-react';
import React from 'react';

const ourServices = [
    {
        title: "Technology Consultancy",
        description: "Strategic advice aligns tech goals with business objectives effectively.",
        features: [
            "Tech Advisory",
            "Strategic Planning",
            "Innovation Workshops",
            "IT Roadmap",
            "Cloud Solutions"
        ],
        linkText: "Read more about technology consultancy"
    },
    {
        title: "UX/UI Design",
        description: "Craft intuitive interfaces to enhance user experience seamlessly.",
        features: [
            "UX/UI Excellence",
            "Design Thinking",
            "Interactive Prototypes",
            "Visual Storytelling",
            "Mobile-First Design",
            "Usability Testing"
        ],
        linkText: "Read more about UX/UI design"
    },
    {
        title: "Web Development",
        description: "Create functional websites for robust online presence and engagement.",
        features: [
            "Any CMS to WordPress Migration",
            "Custom WordPress Development",
            "JS MEAN Stack Web Apps",
            "Custom App Development",
            "Responsive Web Development",
            "Performance Optimization"
        ],
        linkText: "Read more about web development"
    },
    {
        title: "Mobile App Development",
        description: "Build responsive apps tailored for diverse mobile platforms.",
        features: [
            "App Design",
            "Android Solutions",
            "Cross-Platform Apps",
            "UX/UI Excellence",
            "MVP Development"
        ],
        linkText: "Read more about mobile development"
    },
    {
        id: 5,
        icon: "📈",
        title: "Digital Marketing",
        description: "Effective strategies to grow your brand, increase reach, and drive traffic.",
        features: [
            "SEO Optimization",
            "PPC Campaigns",
            "Social Media",
            "Content Creation",
            "Email Marketing",
            "Analytics Insight"
        ],
        linkText: "Read more about digital marketing"
    },
    {
        id: 6,
        icon: "🛠",
        title: "Quality Engineering",
        description: "Ensuring product quality through comprehensive testing and validation.",
        features: [
            "Test Automation",
            "QA Consulting",
            "Performance Testing",
            "Security Testing",
            "Agile QA",
            "Test Strategy"
        ],
        linkText: "Read more about quality engineering"
    },
    {
        id: 7,
        icon: "⚙️",
        title: "DevOps Engineering",
        description: "Streamlining development and operations with automation and continuous integration.",
        features: [
            "CI/CD Pipeline",
            "Automation Solutions",
            "Container Orchestration",
            "Infrastructure as Code (IaC)",
            "Continuous Monitoring",
            "Security Integration"
        ],
        linkText: "Read more about DevOps engineering"
    },
    {
        id: 8,
        icon: "🛠️",
        title: "Product Engineering",
        description: "Building high-quality products from ideation to post-launch support.",
        features: [
            "Product Ideation",
            "Product Architecture",
            "Product Design",
            "Product Development",
            "Product Testing",
            "Technical Support"
        ],
        linkText: "Read more about product engineering"
    },
    {
        id: 9,
        icon: "🤖",
        title: "AI and ML",
        description: "Leveraging AI and machine learning to unlock new business insights and automate processes.",
        features: [
            "ML Solutions",
            "AI Consulting",
            "Data Analytics",
            "Predictive Modeling",
            "NLP Services",
            "Deep Learning"
        ],
        linkText: "Read more about AI and ML"
    },
    {
        id: 10,
        icon: "🔧",
        title: "Support and Maintenance",
        description: "Providing ongoing support to ensure smooth operations and continuous improvements.",
        features: [
            "CI/CD DevOps tools setup",
            "Backup & upgrades",
            "Performance & Security monitoring",
            "Server & Uptime monitoring",
            "Ongoing & features development",
            "Access to email / phone support"
        ],
        linkText: "Read more about support and maintenance"
    },
    {
        id: 11,
        icon: "👥",
        title: "Staff Augmentation",
        description: "On-demand talent solutions to help you scale your team and meet business needs.",
        features: [
            "On-demand Skilled Talent",
            "Tech Staffing",
            "Agile Teams",
            "Expert Augmentation",
            "Rapid Scaling",
            "Strategic Resourcing"
        ],
        linkText: "Read more about staff augmentation"
    }
];

const OurServices = () => {
    return (
        <section className="px-6 bg-gray-100 overflow-hidden">
            <div className="lg:w-[90%] mx-auto">
                {/* Service titles navigation */}
                <div className="mb-8 flex flex-wrap gap-4">
                    {ourServices.map((service) => (
                        <a
                            key={service.title}
                            href={`#${service.title}`}
                            className="text-gray-800 text-sm  rounded-md bg-slate-200  hover:underline p-3 border border-gray-300 hover:bg-slate-800 hover:text-white hover:font-bold"
                        >
                            {service.title}
                        </a>
                    ))}
                </div>

                {/* Services grid */}
                <div className="mt-20">
                    {ourServices.map((service, index) => (
                        <>
                        <div className='pt-20'  key={index} id={service.title} >
                        <div className="p-6 rounded-lg lg:flex lg:justify-between ">
                            <div className="lg:w-[60%]">
                                <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900">{service.title}</h2>
                                <p className=" text-sm sm:text-base md:text-lg text-gray-700 my-6">{service.description}</p>
                                <button className=" text-blue-600 hover:underline border-1 border-blue-600 border p-3 rounded-lg  hover:bg-slate-600 hover:text-white hover:font-bold hover:shadow-xl hover:border-slate-500">{service.linkText}</button>
                            </div>

                            <ul className="mt-10 lg:mt-0 space-y-2 lg:w-[30%]">
                                {service.features.map((feature, featureIndex) => (
                                    <li key={featureIndex} className="text-lg flex items-center text-gray-600">
                                       
                                        <CheckmarkOutline className="text-blue-600 mr-4 lg:font-bold " size={23}  />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <hr className='mt-20 border-t-1 border-gray-300' />
                        </div>
                        </>
                    ))}

                </div>
            </div>
        </section>
    );
};

export default OurServices;
