import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TextField, Button, Box, Typography } from '@mui/material';

const JobApplicationPage = () => {
    // Access position details passed through React Router state
    const location = useLocation();
    const { position } = location.state;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        coverLetter: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Handle form submission, like sending data to an API or email
        console.log('Form Submitted:', formData);
    };

    return (
        <section id="apply" style={{ padding: '16px 5%' }}>
            <div className="container">
                <Typography variant="h2" gutterBottom>Apply for {position.title}</Typography>
                <Typography variant="h6" gutterBottom>{position.description}</Typography>

                <form onSubmit={handleSubmit}>
                    <Box>
                        <TextField
                            fullWidth
                            label="Full Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            margin="normal"
                            required
                        />
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            margin="normal"
                            type="email"
                            required
                        />
                        <TextField
                            fullWidth
                            label="Phone Number"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            margin="normal"
                            type="tel"
                            required
                        />
                        <TextField
                            fullWidth
                            label="Cover Letter"
                            name="coverLetter"
                            value={formData.coverLetter}
                            onChange={handleChange}
                            margin="normal"
                            multiline
                            rows={4}
                            required
                        />

                        <Box marginTop="16px">
                            <Button type="submit" variant="contained">Submit Application</Button>
                        </Box>
                    </Box>
                </form>
            </div>
        </section>
    );
};

export default JobApplicationPage;
