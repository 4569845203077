import React, { useState, useEffect } from 'react';
import Button from '../../components/Button';
import { Fade, Slide, Reveal } from "react-awesome-reveal";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Header() {
    const navigate = useNavigate(); 
    // Array of service offerings to cycle through
    const services = [
        'web applications',
        'AI-driven solutions',
        'ITSM systems',
        'CRM platforms',
        'mobile experiences',
        'end-to-end technology stacks'
    ];

    const [currentService, setCurrentService] = useState(services[0]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentService(prev => {
                const currentIndex = services.indexOf(prev);
                const nextIndex = (currentIndex + 1) % services.length; 
                return services[nextIndex];
            });
        }, 2000); 

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className='lg:min-h-screen w-[100%] pb-20 bg-gradient-to-bl from-[#feb796] via-[#fae1d5] to-white'>
            <div className='ml-10 mx-auto md:ml-40 sm:w-4/5 md:w-2/3 lg:ml-40 lg:w-[70%] hover:scale-105 transition-all'>
                <h2 className='lg:text-5xl text-xl  md:text-6xl font-semibold pt-24 md:pt-48 text-gray-800'>
                    Transforming your business with 

                    <Fade key={currentService} duration={1000} direction='down'>
                        <span className="bg-gradient-to-r from-[#b7291c] to-[#671414] bg-clip-text text-transparent">
                            {/* <span className="bg-gradient-to-r from-[#b7291c] to-[#e76c61] bg-clip-text text-transparent"> */}
                            {currentService}
                        </span>
                    </Fade>
                    and tailored digital solutions
                </h2>
                <p className='lg:text-lg text-xs md:text-xl font-semibold mt-6 md:mt-8 w-[90%] md:w-[80%] text-gray-700'>
                    Our expertise spans across high-performance web and mobile applications,<br/> AI-driven innovations, ITSM systems, and more—empowering your business for the future.
                </p>
                    <Button onClick={()=>navigate('/contact')} label='Connect with us' circleHover={true} className='mt-10 bg-accent before:bg-[#e63434] hover:before:bg-[#e63434]' />
            </div>
        </div>
    );
}

export default Header;
