import React from 'react';

const DigitalSolutions: React.FC = () => {
    return (
        <div className=" py-16 sm:py-24 md:py-32">
            <div className="container mx-auto px-6 sm:px-12 md:px-24 ">
                <p className="lg:text-2xl sm:text-xl md:text-2xl text-gray-700 mb-16">
                    We build <span className='font-medium'> End-to-end digital solutions</span> for start-ups, mid-size businesses, and enterprise-level clients with <br/> <span className='font-medium'> Robust & Integrated Solutions</span>. Our team is filled with experts who have over 10 years of experience in the market. We design and develop products, provide solutions, and help scale businesses on digital platforms with multi-faceted marketing strategies.
                </p>
                <div className="bg-white  text-left">
                    <h3 className="lg:text-4xl sm:text-3xl font-semibold text-gray-800 mb-12">
                        End-to-End Solutions for Start-ups & SMEs
                    </h3>
                    <p className="lg:text-lg sm:text-xl md:text-2xl text-gray-700">
                        Our team of experienced Designers, Product Developers, Project Coordinators, Sales & Marketing Experts can help you with integrated digital solutions to achieve business goals and make data-driven decisions.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default DigitalSolutions;
