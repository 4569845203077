import React, { useState } from "react";

const TechStacks = () => {
  const categories = [
    { id: 1, name: "Frontend" },
    { id: 2, name: "Backend" },
    { id: 3, name: "CMS" },
    { id: 4, name: "Devops" },
    { id: 5, name: "Database" },
    { id: 6, name: "Mobile" },
  ];

  const tools = [
    // Frontend
    { id: 1, name: "React", image: "/images/react.svg", category: "Frontend" },
    { id: 2, name: "Angular", image: "/images/angular.svg", category: "Frontend" },
    { id: 3, name: "Vue.js", image: "/images/vue.svg", category: "Frontend" },
    { id: 4, name: "Svelte", image: "/images/svelte.svg", category: "Frontend" },

    // Backend
    { id: 5, name: "Node.js", image: "/images/node.svg", category: "Backend" },
    { id: 6, name: "Express.js", image: "/images/express.svg", category: "Backend" },
    { id: 7, name: "Django", image: "/images/django.svg", category: "Backend" },
    { id: 8, name: "Ruby on Rails", image: "/images/ruby.svg", category: "Backend" },

    // CMS
    { id: 9, name: "WordPress", image: "/images/wordpress.svg", category: "CMS" },
    { id: 10, name: "Drupal", image: "/images/drupal.svg", category: "CMS" },
    { id: 11, name: "Joomla", image: "/images/joomla.svg", category: "CMS" },
    { id: 12, name: "Magento", image: "/images/magento.svg", category: "CMS" },

    // DevOps
    { id: 13, name: "AWS", image: "/images/aws.svg", category: "Devops" },
    { id: 14, name: "Google Cloud", image: "/images/gcloud.svg", category: "Devops" },
    { id: 15, name: "Jenkins", image: "/images/jenkins.svg", category: "Devops" },
    { id: 16, name: "Gradle", image: "/images/gradle.svg", category: "Devops" },

    // Database
    { id: 17, name: "MongoDB", image: "/images/mongodb.svg", category: "Database" },
    { id: 18, name: "PostgreSQL", image: "/images/postgresql.svg", category: "Database" },
    { id: 19, name: "MySQL", image: "/images/mysql.svg", category: "Database" },
    { id: 20, name: "Redis", image: "/images/redis.svg", category: "Database" },

    // Mobile
    { id: 21, name: "Appium", image: "/images/appium.svg", category: "Mobile" },
    { id: 22, name: "Flutter", image: "/images/flutter.svg", category: "Mobile" },
    { id: 23, name: "React Native", image: "/images/react.svg", category: "Mobile" },
    { id: 24, name: "Swift", image: "/images/swift.svg", category: "Mobile" },
  ];

  const [activeCategory, setActiveCategory] = useState("Frontend");

  const filteredTools = tools.filter(
    (tool) => tool.category === activeCategory
  );

  return (
    <div className="p-6 bg-blue-50">
      {/* Categories */}
      <div className="flex justify-center gap-4 mb-6 flex-wrap">
        {categories.map((category) => (
          <button
            key={category.id}
            className={`px-4 py-2 border rounded-md ${activeCategory === category.name
                ? "bg-blue-500 text-white"
                : "bg-white text-blue-500"
              } hover:bg-blue-500 hover:text-white`}
            onClick={() => setActiveCategory(category.name)}
          >
            {category.name}
          </button>
        ))}
      </div>

      {/* Tools */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {filteredTools.map((tool) => (
          <div
            key={tool.id}
            className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center"
          >
            <img
              src={tool.image}
              alt={tool.name}
              className="w-16 h-16 mb-4 object-contain"
            />
            <h3 className="text-lg font-bold">{tool.name}</h3>
          </div>
        ))}
      </div>
    </div>

  );
};

export default TechStacks;
