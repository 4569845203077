// src/pages/LandingPage.tsx
import React from 'react';
import ServiceHeader from './ServiceHeader';
import OurServices from './OurServices';
import Description from './Description';
import GetInTouch from '../landingpage/GetInTouch';
import Process from './Process';



const Service: React.FC = () => { 
    return (
        <div className=''>
            <ServiceHeader />
            <Description/>
            <OurServices/>
            <GetInTouch/>
            <Process/>
        </div>
    );
};

export default Service;
