import React from "react";
import Button from "../../components/Button";
import { Search, } from '@carbon/icons-react';


const HowWeWork: React.FC = () => {
    return (
        <section className="py-12 ">
            <div className="container w-[80%] mx-auto px-4 sm:px-6 lg:px-8 flex flex-col sm:flex-row justify-between items-center">
                {/* Text Section */}
                <div className="text-center sm:text-left mb-6 sm:mb-0">
                    <h2 className="text-5xl text-accent font-bold mb-4">How Do We Work?</h2>
                    <p className="text-base ">
                        We follow 7D Roadmap to achieve company & client goals.


                    </p>

                </div>

            </div>
            <div className=" flex items-center justify-center">

            <img src="/howWork.jpg" alt=""  />
            </div>
            {/* <div className="flex">
                <div className="flex items-center justify-center  w-1/6 bg-blue-100  h-[250px] rounded-full ">
                    <Search size={62} color="red" />
                </div>
                <div className="flex w-1/6 bg-blue-200  h-[250px] rounded-full ">
                    
                </div>
                <div className="flex w-1/6 bg-blue-300  h-[250px] rounded-full ">

                </div>
                <div className="flex w-1/6 bg-blue-400  h-[250px] rounded-full ">

                </div>
                <div className="flex w-1/6 bg-blue-500  h-[250px] rounded-full ">

                </div>
                <div className="flex w-1/6 bg-blue-600  h-[250px] rounded-full ">

                </div>
            </div> */}
        </section>

    );
};

export default HowWeWork;
