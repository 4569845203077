import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GroupIcon from '@mui/icons-material/Group';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const CoreValues: React.FC = () => {
  const coreValues = [
    {
      title: 'Superior Solutions',
      description: 'Best Solutions come from the Best team.',
      icon: <CheckCircleIcon   className="text-blue-500" />, 
    },
    {
      title: 'Exceptional Talent',
      description: 'We are proud to have some of the great minds and motivated people who make things possible with smart skills and knowledge.',
      icon: <GroupIcon className="text-blue-500" />, 

    },
    {
      title: 'Awesome Service',
      description: 'With a user-focused approach, our expert team with smart solutions brings out the most awesome results.',
      icon: <EmojiEventsIcon className="text-blue-500" />, 

    },
  ];

  return (
    <div className="bg-slate-100 py-16 sm:py-24 md:py-32">
      <div className="container mx-auto px-6 sm:px-12 md:px-24 text-center">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-gray-800 mb-6">
          Core Values that Connect Us
        </h2>
        <p className="text-lg sm:text-xl md:text-2xl text-gray-700 mb-12">
          Our beliefs, culture, and goals might be different, but we have a strong bond of working together like a family to make dreams a reality for us as well as for our clients.
        </p>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 ">
          {coreValues.map((value, index) => (
            <div key={index} className="bg-white p-14 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
               <div className="mb-4 flex justify-center font-extrabold items-center">
                {value.icon} {/* Display the icon */}
              </div>
              <h3 className="text-2xl sm:text-3xl font-semibold text-gray-800 mb-4">
                {value.title}
              </h3>
              <p className="lg:text-base sm:text-xl md:text-2xl text-gray-700">
                {value.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CoreValues;
