import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../components/CustomInput";
import axios from "axios";
import Button from "../../components/Button";
import { CircularProgress } from "@mui/material";
import ContactUsHeader from "./ContactUsHeader";
import toast from "react-hot-toast";
import CustomerFeedback from "../landingpage/CustomerFeedback";
import CoreValues from "../about/CoreValues";
import ContactCard from "./ContactCard";


const serviceOptions = [
  { value: "Web Development", label: "Web Development" },
  { value: "Mobile App Development", label: "Mobile App Development" },
  { value: "Technology Consultancy", label: "Technology Consultancy" },
  { value: "UX/UI Design", label: "UX/UI Design" },
  { value: "Digital Marketing", label: "Digital Marketing" },
  { value: "Quality Engineering", label: "Quality Engineering" },
  { value: "DevOps Engineering", label: "DevOps Engineering" },
  { value: "Product Engineering", label: "Product Engineering" },
  { value: "AI and ML", label: "AI and ML" },
  { value: "Support and Maintenance", label: "Support and Maintenance" },
  { value: "Staff Augmentation", label: "Staff Augmentation" },
];

const ContactUs = () => {

  const [loading, setLoading] = useState(false);



  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      phone_number: "",
      message: "",
      service: ""
    },
    validationSchema: Yup.object({
      full_name: Yup.string().min(3).max(50).required("Full Name is required"),
      email: Yup.string().email("Invalid email address").required("Email is required"),
      phone_number: Yup.string().matches(/^\+?[1-9]\d{1,14}$/, "Invalid phone number format").optional(),
      message: Yup.string().required("Message is required"),
      service: Yup.string().required("Please select a service")
    }),
    onSubmit: async (values,{ resetForm }) => {
      setLoading(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}contact`, values, {
          headers: {
            "Content-Type": "application/json",
          },
        });


         

        if (response?.status == 201){

          console.log("Form submitted successfully:", response);
          toast.success(response?.data?.msg);
        }
        
        resetForm();
      } catch (error) {
        console.error("Error submitting form:", error);
        console.log("Form :", process.env.REACT_APP_API_URL );

      } finally {
        setLoading(false);
      }
    },
  });




  return (
    <div id="contactus" className="bg-gray-50">
      <ContactUsHeader />
      <div className="flex flex-col lg:flex-row w-full p-14 mx-auto">
        {/* Form Section */}
        <div className="w-full lg:w-1/2 space-y-6">
          <form onSubmit={formik.handleSubmit} className="mt-8 space-y-6">
            <p className="mt-2 text-center text-gray-600">
              Fill out the form below, and we'll get in touch with you shortly.
            </p>

            {/* Full Name Field */}
            <CustomInput
              id="full_name"
              name="full_name"
              type="text"
              label="Full Name"
              value={formik.values.full_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.full_name && formik.errors.full_name}
              required
            />

            {/* Email Field */}
            <CustomInput
              id="email"
              name="email"
              type="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && formik.errors.email}
              required
            />

            {/* Phone Number Field */}
            <CustomInput
              id="phone_number"
              name="phone_number"
              type="tel"
              label="Mobile Number"
              value={formik.values.phone_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone_number && formik.errors.phone_number}
            />

            {/* Service Selection */}
            <div>
              <label htmlFor="service" className="block text-sm font-medium text-gray-700">
                Service you are looking for
              </label>
              <select
                id="service"
                name="service"
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={formik.values.service}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select</option>
                {serviceOptions?.map((option: any) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {formik.touched.service && formik.errors.service ? (
                <div className="text-red-500 text-sm">{formik.errors.service}</div>
              ) : null}
            </div>

            {/* Message Field */}
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Your Message
              </label>
              <textarea
                id="message"
                name="message"
                rows={4}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.message && formik.errors.message ? (
                <div className="text-red-500 text-sm">{formik.errors.message}</div>
              ) : null}
            </div>

            {/* Submit Button */}
            <div>
              <Button
                type="submit"
                className="my-6 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
                label={loading ? <CircularProgress size={24} className="text-white" /> : "Submit"}
                circleHover={true}
              />
            </div>
          </form>
        </div>

        {/* Customer Feedback & Contact Info Section */}
        <div className="w-full lg:w-1/2  lg:mt-0">
          <div className="">
            <CustomerFeedback />
            <ContactCard email="info@fladdra.com" phone="+91 9881109889" />
          </div>
        </div>
      </div>
    </div>
  )
};

export default ContactUs;

