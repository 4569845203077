
import React from 'react';
import { Career23 } from './JobOpenings';
import CareerHeader from './CareerHeader';


const Career: React.FC = () => {
    return (
        <div className=''>
            <CareerHeader/>
            <Career23 />
        </div>
    );
};

export default Career;
