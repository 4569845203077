import React, { useState, useEffect, ReactNode } from 'react';
import { Fade, Slide } from "react-awesome-reveal";

interface CustomerFeedbackProps {
    title?: ReactNode;
    subtitle?: ReactNode;
}

const CustomerFeedback: React.FC<CustomerFeedbackProps> = ({ title, subtitle }) => {
    const slides = [
        {
            feedback: "I would like to appreciate the team from Fladdra for their exceptional work. Their dedication, expertise, and innovative approach were evident throughout our collaboration. They went above and beyond to ensure our goals were met, and their proactive communication and timely delivery made all the difference. We couldn’t have asked for a better partner, and I look forward to continuing our successful partnership in the future.",
            name: "John Doe",
            position: "Founder & Director, Fladdra",
            image: "NoProfile.png",
        },
        {
            feedback: "The innovative solutions provided by Fladdra have been crucial to helping us scale our business. Their team worked closely with us to understand our unique needs and challenges, and they crafted customized solutions that exceeded our expectations. Thanks to their expertise, we’ve been able to streamline our operations, improve our customer experience, and achieve greater efficiency across the board. Their support has been invaluable.",
            name: "Jane Smith",
            position: "CEO, TechCorp",
            image: "NoProfile.png",
        },
        {
            feedback: "Integration services provided by Fladdra were flawless and timely. From the beginning, the team demonstrated exceptional technical knowledge and an understanding of our systems, which allowed for a seamless integration with minimal disruption. Their attention to detail and commitment to delivering on time was truly impressive, and we have seen significant improvements in our workflow since working with them. We are grateful for their contributions to our success.",
            name: "Mark Brown",
            position: "COO, Seamless Inc.",
            image: "NoProfile.png",
        },
        {
            feedback: "Fladdra’ team was highly responsive and always ready to tackle any challenge we faced. From the very start, they understood our business goals and worked tirelessly to provide solutions that aligned with our vision. Their collaborative approach and willingness to adapt to our evolving needs have made them a key partner in our journey. The results speak for themselves—our business is more efficient, agile, and customer-focused thanks to their efforts.",
            name: "Anna Lee",
            position: "CTO, ReliTech",
            image: "NoProfile.png",
        },
        {
            feedback: "Their customer-centric approach has truly changed our business. Fladdra took the time to understand the unique needs of our customers and developed solutions that directly addressed those challenges. Their ability to combine cutting-edge technology with a focus on customer satisfaction has allowed us to enhance our products and services significantly. We’ve seen an increase in customer satisfaction and retention, and I’m confident that our partnership will continue to yield outstanding results.",
            name: "Robert King",
            position: "VP, Customer Success, SoftBuild",
            image: "NoProfile.png",
        },
    ];


    const [currentSlide, setCurrentSlide] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if (!isHovered) {
            const timer = setInterval(() => {
                setCurrentSlide((prev) => (prev + 1) % slides.length);
            }, 3000);
            return () => clearInterval(timer);
        }
    }, [slides.length, isHovered]);

    return (
        <div className='flex flex-col justify-center items-center overflow-hidden'>
            <div className='lg:w-[80%] w-[95%]'>
                <Fade direction='up'>
                    <h2 className='text-4xl md:text-6xl font-semibold pt-16 md:pt-8 bg-gradient-to-r from-accent to-[#3f0d09] bg-clip-text text-transparent'>
                        {title}
                    </h2>
                    <p className='text-lg sm:text-xl md:text-xl font-semibold mt-4 md:mt-6'>
                        {subtitle}
                    </p>
                </Fade>

                <div className="mt-8 overflow-hidden"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {
                        slides.map((item, index): any => (
                            <>
                                {
                                    index == currentSlide &&

                                    <Slide direction="right" key={index} duration={2000} >
                                        <div className='bg-[#F4F7FF] rounded-2xl flex justify-center mt-8 border border-gray-200 p-10 '>
                                            <div className='w-[90%]'>
                                                {/* <p className='text-blue-500 text-lg font-semibold text-right mb-6'>Read case study</p> */}
                                                <p className='text-xl mb-6'>{item.feedback}</p>
                                                <div className='flex items-center'>
                                                    <img src={item.image} alt={item.name} className='w-16 h-16 rounded-full mr-4' />
                                                    <div>
                                                        <p className='font-bold'>{item.name}</p>
                                                        <p className='text-sm text-gray-600'>{item.position}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                }
                            </>
                        ))
                    }
                </div>

                <div className="flex justify-center mt-6 space-x-4">
                    {slides.map((_, index) => (
                        <>
                            <div className='h-10 flex items-center cursor-pointer'
                                key={index}
                                onClick={() => setCurrentSlide(index)}
                            >
                                <div className={`w-10 h-[2px]  ${index === currentSlide ? 'bg-blue-500' : 'bg-gray-300'}`}
                                />
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CustomerFeedback;
