import React from "react";
import { Fade } from "react-awesome-reveal";

const brands = [
  { name: "Educationist", logo: "/BlueLogo.svg" },
  { name: "Low code system", logo: "/lcs.png" },
  { name: "Connecting Dots", logo: "/connectingDot.png" },
  { name: "Infidocs", logo: "/infidocs.png" },
  { name: "euronet", logo: "/euronet.png" },
  { name: "octaware_logo-SVG", logo: "/octaware_logo-SVG.svg" },
];

const TrustedBrands: React.FC = () => {
  return (
    <section className="py-12 bg-gray-100  justify-center items-center">
      <Fade duration={2000}>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl mb-16">
            Trusted by brands and businesses all over the globe.
          </h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-6 items-center justify-center flex-1">
            {brands.map((brand, index) => (
              <div
                key={index}
                className="flex justify-center items-center p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
              >
                <img
                  src={brand.logo}
                  alt={brand.name}
                  className="h-24 w-auto object-contain mx-auto"
                />
              </div>
            ))}
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default TrustedBrands;
