import { title } from "process";
import React from "react";
import Button from "../../components/Button";
import { Link } from "react-router-dom";

const Services: React.FC = () => {
    const servicesData = [
        {
            id: 1,
            icon: "📡", // Replace with actual icon if needed
            title: "Technology Consultancy",
            items: [
                "Tech Advisory",
                "Strategic Planning",
                "Innovation Workshops",
                "IT Roadmap",
                "Cloud Solutions",
            ],
        },
        {
            id: 2,
            icon: "🎨",
            title: "UX/UI Design",
            items: [
                "UX/UI Excellence",
                "Design Thinking",
                "Interactive Prototypes",
                "Visual Storytelling",
                "Mobile-First Design",
                "Usability Testing",
            ],
        },
        {
            id: 3,
            icon: "💻",
            title: "Web Development",
            items: [
                "Any CMS to WordPress Migration",
                "Custom WordPress Development",
                "JS MERN Stack Web Apps",
                "Custom Web Apps Development",
                "Responsive Web Development",
                "Performance Optimization",
            ],
        },
        {
            id: 4,
            icon: "📱",
            title: "Mobile Development",
            items: [
                "App Design",
                "iOS Development",
                "Android Solutions",
                "Cross-Platform Apps",
                "UI/UX Excellence",
                "MVP Development",
            ],
        },
        {
            id: 5,
            icon: "📈", // Replace with actual icon if needed
            title: "Digital Marketing",
            items: [
                "SEO Optimization",
                "PPC Campaigns",
                "Social Media",
                "Content Creation",
                "Email Marketing",
                "Analytics Insight",
            ],
        },
        {
            id: 6,
            icon: "🛠", // Replace with actual icon if needed
            title: "Quality Engineering",
            items: [
                "Test Automation",
                "QA Consulting",
                "Performance Testing",
                "Security Testing",
                "Agile QA",
                "Test Strategy",
            ],
        },
        {
            id: 7,
            icon: "⚙️", // Replace with actual icon if needed
            title: "DevOps Engineering",
            items: [
                "CI/CD Pipeline",
                "Automation Solutions",
                "Container Orchestration",
                "Infrastructure as Code (IaC)",
                "Continuous Monitoring",
                "Security Integration",
            ],
        },
        {
            id: 8,
            icon: "🛠️", // Replace with actual icon if needed
            title: "Product Engineering",
            items: [
                "Product Ideation",
                "Product Architecture",
                "Product Design",
                "Product Development",
                "Product Testing",
                "Technical Support",
            ],
        },
        {
            id: 9,
            icon: "🤖", // Replace with actual icon if needed
            title: "AI and ML",
            items: [
                "ML Solutions",
                "AI Consulting",
                "Data Analytics",
                "Predictive Modeling",
                "NLP Services",
                "Deep Learning",
            ],
        },
        {
            id: 10,
            icon: "🔧", // Replace with actual icon if needed
            title: "Support and Maintenance",
            items: [
                "CI/CD DevOps tools setup",
                "Backup & upgrades",
                "Performance & Security monitoring",
                "Server & Uptime monitoring",
                "Ongoing & features development",
                "Access to email / phone support",
            ],
        },
        {
            id: 11,
            icon: "👥", // Replace with actual icon if needed
            title: "Staff Augmentation",
            items: [
                "On-demand Skilled Talent",
                "Tech Staffing",
                "Agile Teams",
                "Expert Augmentation",
                "Rapid Scaling",
                "Strategic Resourcing",
            ],
        },
        {
            id: 12,
            icon: "✉️", // Replace with a relevant icon if needed
            title: "Get In Touch",
            items: [
                "Let's build and scale your digital products. We are always open to discuss new projects, creative ideas, or opportunities to be part of your vision."
            ],
            isSpecial: true, // Custom property for different styling
            buttonText: "Learn More", // Button text
        }

    ];


    return (
        <section className="py-12 bg-white bg-gradient-to-bl from-[#fbede6] via-[#f7f0ed] to-white">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                {/* Text Section */}
                <div className="text-center sm:text-left mb-12">
                    <h2 className="text-3xl sm:text-4xl lg:text-5xl p-2 font-bold mb-6 w-full sm:w-2/3 lg:w-1/2 mx-auto sm:mx-0 bg-gradient-to-r from-accent to-[#3f0d09] bg-clip-text text-transparent">
                        Services we help you with to achieve goals
                    </h2>
                    <p className="text-sm sm:text-base lg:text-lg  leading-relaxed w-full sm:w-2/3 lg:w-1/2 mx-auto sm:mx-0">
                        Acting as a Catalyst between the brands and the corporates, we take complete responsibility for the timely deliverables and continuous dedicated support.
                    </p>
                </div>

                {/* Cards Section */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8 ">
                    {servicesData.map((service) => (
                        <div
                            key={service.id}
                            className={`p-4 sm:p-6 rounded-lg shadow-md transition-all duration-300 hover:border-gray-700 hover:border-3 hover:shadow-xl   ${service.isSpecial
                                ? "bg-green-100 text-black hover:bg-green-400"
                                : "bg-gray-700 text-white hover:bg-white hover:text-black hover:shadow-2xl hover:border-slate-200 hover:scale-105 hover:border "
                                }`}
                        >
                            <div className="text-3xl sm:text-4xl mb-4">{service.icon}</div>
                            <h3 className="text-lg sm:text-xl font-bold mb-4">{service.title}</h3>
                            <ul className="list-disc space-y-2 pl-4">
                                {service.items.map((item, index) => (
                                    <li key={index} className="text-xs sm:text-sm">
                                        {item}
                                    </li>
                                ))}
                            </ul>
                            {service.isSpecial && (
                                <Link to={'/contact'}>
                                    <Button
                                        onClick={() => null}
                                        label="Let's Talk"
                                        circleHover={true}
                                        className="mt-6 sm:mt-10 w-full bg-primary"
                                    />
                                </Link>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>

    );
};

export default Services;
