// src/pages/LandingPage.tsx
import React from 'react';
import Header from './Header';
import TrustedBrands from './TrustedBrads';
import GetInTouch from './GetInTouch';
import CustomerFeedback from './CustomerFeedback';
import OurWork from './OurWork';
import Services from './Services';
import HowWeWork from './HowWeWork';
import FladdraAtGlance from './FladdraAtGlance';

const LandingPage: React.FC = () => {
    return (
        <div className=''>
           <Header/>
           <TrustedBrands/>
           <CustomerFeedback title={' Our customers tell it better'} subtitle={'Learn how our customers use our services to scale their businesses.'}/>
           {/* <FladdraAtGlance/> */}
           <OurWork/>
           <Services/>
           <GetInTouch/>
           <HowWeWork/>

        </div>
    );
};

export default LandingPage;
