// src/pages/LandingPage.tsx
import React from 'react';
import TrustedBrands from '../landingpage/TrustedBrads';
import CustomerFeedback from '../landingpage/CustomerFeedback';
import GetInTouch from '../landingpage/GetInTouch';
import OurWork from '../landingpage/OurWork';
import Services from '../landingpage/Services';
import HowWeWork from '../landingpage/HowWeWork';
import Header from '../landingpage/Header';
import AboutHeader from './AboutHeader';
import DigitalSolutions from './DigitalSolutions';
import CoreValues from './CoreValues';


const About: React.FC = () => {
    return (
        <div className=''>
           <AboutHeader/>
           <DigitalSolutions/>
           <GetInTouch/>
           <CoreValues/>

        </div>
    );
};

export default About;
