import React from "react";
import Button from "../../components/Button";
import { Link } from "react-router-dom";


const GetInTouch: React.FC = () => {
    return (
        <section className="py-12 bg-gradient-to-r from-slate-950 via-slate-800 to-slate-700">
            <div className=" w-[80%] mx-auto px-4 sm:px-6 lg:px-8 flex flex-col sm:flex-row justify-between items-center">
                {/* Text Section */}
                <div className="text-center sm:text-left mb-6 sm:mb-0">
                    <h2 className="text-5xl text-white font-bold mb-4">Get In Touch</h2>
                    <p className="text-base lg:w-1/2 text-neutral-200 ">
                        Let's build and scale your digital products. We are always open to
                        discuss new projects, creative ideas, or opportunities to be part of
                        your vision.
                    </p>
                </div>

                {/* Button Section */}
                <div className="flex items-center justify-center sm:justify-end">
                    <Link to="/contact"> 
                        <Button
                            label="Let&apos;s Talk"
                            circleHover={true}
                            className="mt-10"
                        />
                    </Link>
                </div>
            </div>
        </section>

    );
};

export default GetInTouch;
