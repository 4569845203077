import React from "react";
import {
    Education,
    SettingsAdjust,
    Rocket,
    Events
} from '@carbon/icons-react';
import { Fade } from "react-awesome-reveal";

const Process = () => {
    const cardData = [
        {
            id: 1,
            icon: <Education className="text-blue-500 group-hover:text-white" size={100} />,
            title: "Discover",
            summary:
                "Revolutionizing education with AI-powered tools to enhance learning outcomes and streamline teaching processes.",
        },
        {
            id: 2,
            icon: <SettingsAdjust className="text-blue-500 group-hover:text-white" size={100} />,
            title: "Strategize",
            summary:
                "Delivering seamless IT service management solutions to optimize workflows and improve service delivery.",
        },
        {
            id: 3,
            icon: <Events className="text-blue-500 group-hover:text-white" size={100} />,
            title: "Execute",
            summary:
                "Empowering businesses with customer relationship management tools to boost engagement and drive sales growth.",
        },
        {
            id: 4,
            icon: <Rocket className="text-blue-500 group-hover:text-white" size={100} />,
            title: "Launch",
            summary:
                "Empowering businesses with customer relationship management tools to boost engagement and drive sales growth.",
        },
    ];

    return (
        <section className="py-12 bg-white">
            <div className="mx-auto w-[80%] ">
                <h2 className="pb-10 text-3xl md:text-5xl font-bold text-gray-800 bg-gradient-to-r from-accent to-[#3f0d09] bg-clip-text text-transparent ">
                    Our 4-step process to <br />build great products
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {cardData.map((card) => (
                        <div
                            key={card.id}
                            className="bg-white shadow-xl border-slate-100 border ease-in-out rounded-lg   group hover:bg-blue-500"
                        >

 
                            <div className="w-full h-48 flex items-center justify-center ">
                                {card.icon}
                            </div>


                            <div className="px-6 pb-4 text-center transition-all ease-in-out">
                                <h3 className="text-lg font-bold text-gray-800 mb-2 group-hover:text-white ">
                                    {card.title}
                                </h3>
                                <p className="text-gray-600  group-hover:text-white ">
                                    {card.summary}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Process;
