// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/landingpage/LandingPage';
import ContactUs from './pages/contactus/ContactUs';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import About from './pages/about/about';
import Service from './pages/service/Services';
import Solutions from './pages/solutions/Solutions';
import { Toaster } from 'react-hot-toast';
import Career from './pages/career/Career';
import JobApplicationPage from './pages/career/JobApplicationPage';
import BlogPage from './pages/blog/BlogPage';

const App: React.FC = () => {
    return (
        <Router>
            <NavBar />
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/about" element={<About />} />
                <Route path="/services" element={<Service />} />
                <Route path="/solutions" element={<Solutions />} />
                <Route path="/careers" element={<Career />} />
                <Route path="/apply" element={<JobApplicationPage />} />
                <Route path="/Blog" element={<BlogPage />} />

                
            </Routes>
            <Footer />

            <Toaster
                position="bottom-center"
                // reverseOrder={false}
                toastOptions={{
                    duration: 3000,
                }}
            />

        </Router>
    );
}

export default App;
