import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Button from './Button'; // Import the Button component

const NavBar: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const menuItems = [
    'About',
    'Services',
    'Solutions',
    // 'Industries',
    'Blog',
    'Careers',
    'Contact',
    // 'Work',
  ];

  return (
    <header className="fixed top-0 left-0 w-full bg-white shadow-md z-50">
      <div className="container mx-auto flex items-center justify-between px-4 py-3 w-[80%]">
        {/* Logo */}
        <div className="flex items-center space-x-4 text-2xl font-bold text-[#B7291C]">
          <img
            src="/FladdraLogo.png"
            alt="Fladdra Logo"
            className="w-12 h-12 object-contain"
          />
          <Link to="/" className="hover:text-[#B7291C]">
            Fladdra
          </Link>
        </div>

        {/* Desktop Menu */}
        <nav className="hidden md:flex items-center space-x-6">
          {menuItems.map((item) => (
            <Link
              key={item}
              to={`/${item.toLowerCase().replace(' ', '-')}`}
              className="text-gray-700 hover:text-blue-600 transition-colors"
            >
              {item}
            </Link>
          ))}
          {/* Let's Talk Button */}
          <Link to="/contact">
            <Button
              label="Let's Talk"
              // onClick={() => console.log("Let's Talk")}
              // className="bg-blue-600 hover:bg-blue-700"
              circleHover={true}
            />
          </Link>
        </nav>

        {/* Mobile Menu Button */}
        <button
          className="md:hidden text-gray-700"
          onClick={toggleDrawer}
          aria-label="Toggle Menu"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 5.25h16.5m-16.5 6h16.5m-16.5 6h16.5"
            />
          </svg>
        </button>
      </div>

      {/* Mobile Drawer */}
      {isDrawerOpen && (
        <div className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-40" onClick={toggleDrawer}>
          <div
            className="fixed right-0 top-0 h-full bg-slate-800 w-64 shadow-md z-50 p-4"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="text-white mb-4"
              onClick={toggleDrawer}
              aria-label="Close Menu"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <nav className="flex flex-col space-y-4">
              {menuItems.map((item) => (
                <Link
                  key={item}
                  to={`/${item.toLowerCase().replace(' ', '-')}`}
                  className="text-white hover:text-blue-300 transition-colors"
                >
                  {item}
                </Link>
              ))}
              {/* Let's Talk Button */}
              <Link to="/contact">
                <Button
                  label="Let's Talk"
                  // onClick={() => console.log("Let's Talk")}
                  circleHover={true}
                  className="bg-red-600" 
                />
              </Link>
            </nav>
          </div>
        </div>
      )}
    </header>
  );
};

export default NavBar;
