// src/pages/LandingPage.tsx
import React from 'react';
import BlogHeader from './BlogHeader';
import OurBlog from './OurBlog';

const BlogPage: React.FC = () => {
    return (
        <div className=''>
         <BlogHeader/>
         <OurBlog/>
        </div>
    );
};

export default BlogPage;
