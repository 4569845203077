

function AboutHeader() {


  return (
    <div className='h-[60vh] bg-slate-200 flex justify-center items-center'>
      <img src="/bg.jpg" className="h-[60vh] w-full object-cover opacity-30" />

      <div className='text-center  px-10 absolute '>
        <h2 className='text-4xl sm:text-5xl md:text-6xl font-semibold text-gray-800'>
          A Team of
          <br />
          Digital Innovators
        </h2>
        <p className='text-lg sm:text-xl md:text-2xl text-gray-700 mt-4'>
          Designing, developing, and growing businesses.
        </p>
      </div>
    </div>


  );
}

export default AboutHeader;
