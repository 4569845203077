import React from 'react';

// Sample array of blog data
const blogPosts = [
  {
    id: 1,
    title: "How to Start Coding",
    description: "A beginner's guide to starting a career in coding. Tips, resources, and steps to take.",
    image: "/blog.jpg",
    link: "/blog/how-to-start-coding"
  },
  {
    id: 2,
    title: "Understanding React",
    description: "An introduction to React, its components, and how to build modern web apps.",
    image: "/blog.jpg",
    link: "/blog/understanding-react"
  },
  {
    id: 3,
    title: "Web Development Trends in 2024",
    description: "Explore the latest trends and technologies in web development for the upcoming year.",
    image: "/blog.jpg",
    link: "/blog/web-development-trends-2024"
  },
  // Add more blog posts here
];

const OurBlog = () => {
  return (
    <div className="container mx-auto p-6">
      <h2 className="text-3xl font-semibold text-center mb-8">Our Blog</h2>
      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {blogPosts.map((post) => (
          <div className="bg-white rounded-lg shadow-lg overflow-hidden" key={post.id}>
            <img src={post.image} alt={post.title} className="w-full h-48 object-cover" />
            <div className="p-4">
              <h3 className="text-xl font-semibold text-gray-800">{post.title}</h3>
              <p className="text-gray-600 mt-2">{post.description}</p>
              {/* <a
                // href={post.link}
                className="text-blue-500 hover:underline mt-4 inline-block"
              >
                Read More
              </a> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurBlog;
